import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import DataService from "../services/api/data_services";
import Layout from "../components/layouts/layout";
import Typography from "@material-ui/core/Typography";
import Megaview from "../templates/MegaView";
import FormDialog from "../templates/DownloadablePopup";
import clutchLogo from "../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";
import RightArrow from "../assets/Icons/go.svg";
import RightArrowWhite from "../assets/Icons/accordian_white.svg";
import BackArrowIcon from "../assets/Images/accordian.svg";
import Button from "../components/Button";
import { BASE_SITE_URL } from "../shared/constants";

//Image Banner
import ImageBanner from "../templates/ImageBanner";
import BannerBgImage from "../assets/Images/mvp_banner.png";

//Info View
import Infoview from "../templates/InfoView";

//Image List
import ImageList from "../templates/ImageList";
import ImageListDynamic from "../templates/ImageListDynamic";

//Miniview
import Miniview from "../templates/MiniView";

// Two Column Contact info
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";
import Clm1Img from "../assets/Icons/phone.svg";
import Clm2Img from "../assets/Icons/mail.svg";

//Mini Image Banner
import MiniImageBanner from "../templates/MiniImageBanner";
import MiniImgBnrBgImg from "../assets/Images/contact_us_bg.png";

//BlurCardList
import BlurCardList from "../templates/BlurCardList";
import MiniCardBgImage1 from "../assets/Images/Homepage/mobile_application_development.png";
import MiniCardBgImage2 from "../assets/Images/Homepage/web_application_development.png";
import MiniCardBgImage3 from "../assets/Images/Homepage/full_product_development.png";
import MiniCardBgImage5 from "../assets/Images/Homepage/Product_designing_and_Prototyping.png";
import MiniCardBgImage6 from "../assets/Images/Homepage/product_scaling.png";
import MiniCardBgImage7 from "../assets/Images/Homepage/code_ux_audit.png";
import MiniCardBgImage8 from "../assets/Images/Homepage/digital_transformation.png";
import MiniCardBgImage9 from "../assets/Images/Homepage/machine_learning.png";

//Six Hover Cards
import SixPlainCards from "../templates/SixPlainCards";
import HoverCardWithBtnIcon1 from "../assets/Icons/outrun.svg";
import HoverCardWithBtnIcon2 from "../assets/Icons/Group_1.svg";
import HoverCardWithBtnIcon3 from "../assets/Icons/Group_2.svg";
import HoverCardWithBtnIcon4 from "../assets/Icons/Group_3042.svg";
import HoverCardWithBtnIcon5 from "../assets/Icons/Group_3.svg";

//Click To View Card
import ClickToViewCard from "../templates/ClickToViewCard";
import ClickToViewCardImg1 from "../assets/Images/ClickToViewCard/Business-Model-Valuation.png";
import ClickToViewCardImg2 from "../assets/Images/ClickToViewCard/Proof-of-Concept.png";
import ClickToViewCardImg3 from "../assets/Images/ClickToViewCard/Prototype.png";
import ClickToViewCardImg4 from "../assets/Images/ClickToViewCard/Minimum_Viable_Product.png";

//Icon Card List
import IconCardList from "../templates/IconCardList";
import IconCardListIcon1 from "../assets/Images/IconCardList/Creative_Idea.svg";
import IconCardListIcon2 from "../assets/Images/IconCardList/Software_Companies.svg";
import IconCardListIcon3 from "../assets/Images/IconCardList/Feedback_Audience.svg";
import IconCardListIcon4 from "../assets/Images/IconCardList/Presentation.svg";
import IconCardListIcon5 from "../assets/Images/IconCardList/Work_Flow.svg";
import IconCardListIcon6 from "../assets/Images/IconCardList/MRR.svg";
// Mobile movie slider
import MobileMovieSlider from "../components/MobileMovieSlider";
import awardsForMobile from "../assets/Images/About Us/awards____2x.png";

import ogimage from "../assets/Images/ogImages/OG_MVP.png";
import ourOffer from "../assets/Images/scrollImages/Group_6110_2x.png";

const MvpDevelopment = () => {
  const [page, setPage] = useState(1);
  const [awardsData, setAwardsData] = useState([]);
  const [limit, setLimit] = useState(6);
  const [articleRawData, setArticleRawData] = useState([]);
  const [articleLimit, setArticleLimit] = useState(6);
  const [articlePage, setArticlePage] = useState(1);
  const [articleData, setArticleData] = useState([]);
  const [megaviewData, setMegaViewData] = useState([]);
  const [clientStoryData, setClientStoryData] = useState([]);
  const [clientStoryRawData, setClientStoryRawData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const [openUp, setOpen] = useState(false);

  const [timeLeft, setTimeLeft] = useState(5);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) {
      setOpen(true);
      return;
    }

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 5);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  const toggleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {
    DataService.getData(
      "/admin/api/all-services?limit=1&type=White Paper&service_type=PD - MVP Development"
    ).then((response) => {
      setMegaViewData(response);
    });
    DataService.getData(
      "/admin/api/all-services?type=Awards&service_type=PD - MVP Development&limit=5"
    ).then((response) => {
      setAwardsData(response.data);
    });
    //     const openDialog = () => setTimeout(() => setOpen(true),20)
    // const closeDialog = () => setOpen(false)
    // console.log('open :>> ', openUp);
    // let openPopup = minutes === 1 ? true :false;
  }, []);
  useEffect(() => {
    DataService.getData(
      `/admin/api/all-services?limit=${articleLimit}&page=${articlePage}&type=Article&service_type=PD - MVP Development&component=mini_view`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleLimit, articlePage]);
  useEffect(() => {
    DataService.getData(
      `/admin/api/all-services?limit=${limit}&page=${page}&type=Client Story&service_type=PD - MVP Development&component=mini_view`
    ).then((response) => {
      setClientStoryData([...clientStoryData, ...response.data]);
      setClientStoryRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);

  /**
   * Image Banner
   */
  const ImageBannerData = {
    bnrImg: BannerBgImage,
    bnrImgTitle: (
      <>
        Minimum
        <br />
        Viable&nbsp;Product&nbsp;(MVP)
        <br />
        Development
      </>
    ),
    bnrImgSubTitle: "How can we help you today?",
    servicesColumn1: {
      data: [
        {
          service: "Mobile Application Development",
          link: "/mobile-application-development",
        },

        {
          service: "MVP Development",
          link: "/mvp-development",
        },
        {
          service: "Code & UX Audit",
          link: "/code-ux-audit",
        },
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Web Application Development",
          link: "/web-application-development",
        },
        {
          service: "Product Designing and Prototyping",
          link: "/product-designing-and-prototyping",
        },
        {
          service: "Digital Transformation",
          link: "/digital-transformation",
        },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Full Product Development",
          link: "/full-product-development",
        },
        {
          service: "Product scaling",
          link: "/product-scaling",
        },
        {
          service: "Machine Learning",
          link: "/machine-learning",
        },
      ],
    },
  };

  /**
   * Infoview
   */
  const InfoviewData = {
    InfoviewTitle: (
      <div className="ttc">
        <span className="red-line3letter">Bui</span>ld an MVP Product
      </div>
    ),
    tag: "h2",
    InfoviewContent: (
      <>
        Before you move on to create a new digital product, you should
        definitely consider launching a Minimum Viable Product (MVP) to assess
        your business risk. Use MVP design, lower cost of market-testing, and
        use real-time feedback to improve your product. MVP development
        guarantees several benefits for start-ups, such as market validation,
        resource optimisation, early acquisition of users, among others.
        Building an MVP is the best way to get feedback from real users at an
        early stage without having to risk releasing a product that is not going
        to work on the market.
      </>
    ),
  };

  /**
   * Infoview1
   */
  const InfoviewData1 = {
    InfoviewTitle: (
      <div className="ttc">
        <span className="red-line3letter">Our</span> Experience Building MVPs
      </div>
    ),
    tag: "h4",
    InfoviewContent: (
      <>
        At Digiryite, we use the latest and the most advanced programming
        languages such as JavaScript, Python and Node.js to design our MVPs. We
        have experience of creating over 77 number of MVPs for our clients
        across the globe. Here are some of the interesting case studies that
        will highlight our expertise in creating MVPs for your product
        development.
      </>
    ),
  };

  /**
   * Imagelist
   */

  const ImagelistData = {
    ImageListTitle: (
      <div className="ttc">
        Awards &<br />
        <span className="red-line3letter">Rec</span>ognitions
      </div>
    ),
    tag: "h5",
    ImageListButtonColor: "primary",
    ImageListButtonLabel: "Find out More",
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  const miniImageBannerData = {
    Id: "minibanner1",
    MiniImageBannerBgImg: MiniImgBnrBgImg,
    MiniImageBannerAltTxt: "Contact Us to estimate your product",
    HoverLinkContent: "Contact Us",
    NearHoverLinkContent: " to ",
    PlainContent: "estimate your product",
    route: "/contact-us",
  };

  /**
   * Click To View Card
   */

  const ClickToViewCardData = [
    {
      title: "Starting a new business",
      heading: (
        <>
          <span className="black-line3letter">Sta</span>rting a new business
        </>
      ),
      content:
        "For a start-up or an entrepreneur, checking the project concept in action is an extremely important phase. With an MVP development, you can save time and build your product with basic functionalities just to test them on your user. Together with your team, we will help you to create and develop a product that will reflect your business goals.",
      image: ClickToViewCardImg1,
    },
    {
      title: "Adding new features",
      heading: (
        <>
          <span className="black-line3letter">Add</span>ing new features
        </>
      ),
      content:
        "After launching an MVP, we help you gather user responses to determine the future iterations of your product roadmap. We group similar features and initiatives into themes that will help you in releasing your product that is truly valuable for your customers.",
      image: ClickToViewCardImg2,
    },
    {
      title: "Digital Transformation",
      heading: (
        <>
          <span className="black-line3letter">Dig</span>ital Transformation
        </>
      ),
      content:
        "The main function of embracing digital transformation is the way that businesses reinvent business models in new paradigms for customer interaction, business productivity and informed decision making. At Digiryte, we possess the expertise, experience, and resources to boost your organisation's digital transformation process.",
      image: ClickToViewCardImg3,
    },
    {
      title: "Validating Your Business Ideas",
      heading: (
        <>
          <span className="black-line3letter">Val</span>idating Your Business
          Ideas
        </>
      ),
      content:
        "Validation is an ongoing process and a certain type of validation is needed at all stages in order to ensure the product maintaining strength in the market. The team at Digiryte will work closely with you to identify the most appropriate business goals and then test the product developed to demonstrate the initial assumption.",
      image: ClickToViewCardImg4,
    },
  ];

  /**
   * Six Hover Card
   */

  const SixHoverCardData = {
    HoverCardWithBtnvariant: "contained",
    HoverCardWithBtncolor: "primary",
    HoverCardWithBtnlabel: "Get in Touch",
    HoverCardWithBtntitle: (
      <>
        Need help <br /> with your <br /> MVP idea?
      </>
    ),
    whiteCard: "no",
    HoverCardWithBtntitle1: "Overtake Competition",
    HoverCardWithBtntitle2: "Get Real-Time Response",
    HoverCardWithBtntitle3: "Test Product Hypothesis",
    HoverCardWithBtntitle4: "Insight-led Product Development",
    HoverCardWithBtntitle5: "Knowledge Sharing",
    HoverCardWithBtnIcon1: HoverCardWithBtnIcon1,
    HoverCardWithBtnIcon2: HoverCardWithBtnIcon2,
    HoverCardWithBtnIcon3: HoverCardWithBtnIcon3,
    HoverCardWithBtnIcon4: HoverCardWithBtnIcon4,
    HoverCardWithBtnIcon5: HoverCardWithBtnIcon5,
    HoverCardWithBtnContent1:
      "With an MVP, you can release a fully functional product to the market within 4-8 weeks to test the viability of the product in the marketplace.",
    HoverCardWithBtnContent2:
      "The MVP product gives you the ability to get feedback from real users, the ability to optimise, rebrand and improve the product before the final launch stage.",
    HoverCardWithBtnContent3:
      "Using MVP, you can test the hypothesis and understand the nearest business development plan.",
    HoverCardWithBtnContent4:
      "The aim of the MVP is to build-measure-learn that provides immediate value for the release of the product and the collection of valuable data. Data and feedback are then used to improve future product iterations.",
    HoverCardWithBtnContent5:
      "An MVP with an external team is a great opportunity to experience the lean startup approach in practice and immerse oneself in the agile and scrum frameworks.",
    HoverCardWithBtnIconAlt1: "E-Commerce Website Development ",
    HoverCardWithBtnIconAlt2: "SAAS Product Development ",
    HoverCardWithBtnIconAlt3: "Platform Based Product Development ",
    HoverCardWithBtnIconAlt4: "CRM Development ",
    HoverCardWithBtnIconAlt5: "LMS Development ",
    route: "/contact-us",
  };

  /**
   * Blur Card List Data
   */

  const BlurCardListData = {
    cardsData: [
      {
        image: MiniCardBgImage1,
        title: "Mobile Application Development",
        subTitle:
          "Take business to your customers by building advanced native and hybrid mobile apps for both Android and iOS Platforms.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mobile-application-development",
      },
      {
        image: MiniCardBgImage2,
        title: "Web Application Development",
        subTitle:
          "Accelerate different functions of your business by creating custom web applications using the latest in digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/web-application-development",
      },
      {
        image: MiniCardBgImage3,
        title: "Full Product Development",
        subTitle:
          "The process of full product development encompasses all steps needed to take your product from concept to market place.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/full-product-development",
      },
      {
        image: MiniCardBgImage5,
        title: "Product Designing and Prototyping",
        subTitle:
          "Create a prototype of your product to visualise your idea and generate feedback without incurring the building costs of a fully functioning system.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-designing-and-prototyping",
      },
      {
        image: MiniCardBgImage6,
        title: "Product Scaling",
        subTitle:
          "Found your product-market fit? Great! Let us help you with a profitable scaling process that provides both sustainability and stability for your product.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-scaling",
      },
      {
        image: MiniCardBgImage7,
        title: "Code & UX Audit",
        subTitle:
          "With a regular audit, we can help you fix your product, improve its performance, gain new users, and boost your brand reputation in the marketplace.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/code-ux-audit",
      },
      {
        image: MiniCardBgImage8,
        title: "Digital Transformation",
        subTitle:
          "Update your traditional business processes and customer experience to meet the ever-changing market requirements with the help of next-generation digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/digital-transformation",
      },
      {
        image: MiniCardBgImage9,
        title: "Machine Learning",
        subTitle:
          "Through machine learning, we can help you make informed decisions to improve business scalability and operational efficiency.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/machine-learning",
      },
    ],
    lastCardData: {
      title: "Let's Talk",
      subTitle: "We'd love to help with your product.",
      variant: "contained",
      label: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
    },
  };

  /**
   * Icon Card List Data
   */

  const IconCardListData = [
    {
      icon: IconCardListIcon1,
      altTxt: "Two different flexible proposals with solution and pricing",
      heading: "Two different flexible proposals with solution and pricing",
    },
    {
      icon: IconCardListIcon2,
      altTxt: "Working piece of code at end of each milestone",
      heading: "Working piece of code at end of each milestone",
    },
    {
      icon: IconCardListIcon3,
      altTxt: "Accessible customer care by accountable team",
      heading: "Accessible customer care by accountable team",
    },
    {
      icon: IconCardListIcon4,
      altTxt: "Product workshops and consulting by experts",
      heading: "Product workshops and consulting by experts",
    },
    {
      icon: IconCardListIcon5,
      altTxt: "Transparent development process",
      heading: "Transparent development process",
    },
    {
      icon: IconCardListIcon6,
      altTxt: "Contineous knowledge transfer",
      heading: "Contineous knowledge transfer",
    },
  ];
  const quotedMiniCardData = {
    title: "Mobile App Dev for Automative Sales App",
    heading:
      "We can’t speak highly enough of Digiryte’s team because they bent over backward to help us launch our product.",
    content: "Founder, Bumper UK",
    footerContent: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Authentic client review from</div>
        <div style={{ paddingLeft: "5px" }}>
          <img src={clutchLogo} alt="Clutch" height="50px" />
        </div>
      </div>
    ),
  };

  const miniviewDataArticle = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };
  const miniviewDataCaseStudy = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };

  // Mobile movie slider
  const mobMovieSlider = {
    imageUrl: awardsForMobile,
  };
  const mobMovieSlider1 = {
    imageUrl: ourOffer,
  };
  return (
    <Layout
      seo={{
        title: "What we do: MVP Development | Digiryte",
        ogtitle: "What we do: MVP Development | Digiryte",
        description:
          "Minimum Viable Product guarantees the benefits for start-ups, such as market validation, resource optimisation, early acquisition of users, among others.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/mvp-development`,
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-50 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="What we do"
          routepath="/what-we-do"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          What we do
        </Typography>
      </div>
      <div className="mt-30"></div>
      <Infoview InfoviewData={InfoviewData} />

      <div className="mt-50 body-container">
        <h3 className="title-header ttc">
          <span className="red-line3letter">Ben</span>efits of Developing MVP
        </h3>
      </div>
      <div className="mt-50"></div>
      <SixPlainCards SixHoverCardData={SixHoverCardData} />
      <div className="body-container mt-70">
        <h4 className="title-header ttc">
          <span className="red-line3letter">Wha</span>t is your business goal
          for MVP?
        </h4>
      </div>
      <div className="mt-30 body-container ">
        <ClickToViewCard data={ClickToViewCardData} />
      </div>
      <div className="mt-50"></div>
      <MiniImageBanner miniImageBannerData={miniImageBannerData} />
      {clientStoryData && clientStoryData.length > 0 && (
        <div className="mt-70">
          <Infoview InfoviewData={InfoviewData1} />
          <div className="mt-50">
            <Miniview
              miniviewData={clientStoryData}
              total={clientStoryData.total}
              miniviewData2={miniviewDataCaseStudy}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      {clientStoryRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setPage(page === 1 ? page + 2 : page + 1);
              setLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="Load More"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="Load More"
            />
          </div>
        </div>
      )}
      {awardsData.length > 0 && <div className="mt-70"></div>}
      {awardsData.length > 0 && (
        <div className="hide-in-mobile">
          {" "}
          {/* <ImageList ImagelistData={ImagelistData} /> */}
          <ImageListDynamic
            ImagelistData={awardsData.length > 0 ? ImagelistData : ""}
            awardsData={awardsData}
          />
        </div>
      )}
      {/* <FormDialog handlePopup={setOpen} openPopup={openUp} /> */}
      <div className="hide-in-web body-container">
        <h3 className="title-header">Awards & Recognitions</h3>
      </div>
      <MobileMovieSlider movieSlider={mobMovieSlider} slideid="slide1" />

      <div className="mt-70"></div>
      <div className="body-container mt-70">
        <h5 className="title-header ttc">
          <span className="red-line3letter">Our</span> Offer
        </h5>
        <div className="mt-50">
          <div className="hide-in-mobile">
            {" "}
            <IconCardList IconCardListData={IconCardListData} />
          </div>
          <MobileMovieSlider movieSlider={mobMovieSlider1} slideid="slide2" />
        </div>
      </div>
      {megaviewData.data && megaviewData.data.length > 0 && (
        <div className="mt-70 body-container">
          <Megaview MegaviewData={megaviewData.data} />
        </div>
      )}
      {articleData && articleData.length > 0 && (
        <div className="mt-70">
          <div className="body-container">
            <h5 className="title-header ttc">
              <span className="red-line3letter">Rec</span>ommended Reading
            </h5>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={articleData}
              total={articleData.total}
              miniviewData2={miniviewDataArticle}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setArticlePage(
                articlePage === 1 ? articlePage + 2 : articlePage + 1
              );
              setArticleLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="READ MORE"
              tip="READ MORE"
            />
          </div>
        </div>
      )}

      <div className="body-container mt-70">
        <h3 className="title-header ttc">
          <span className="red-line3letter">Oth</span>er Services
        </h3>
      </div>
      <div className="mt-50">
        <BlurCardList componentId="1" BlurCardListData={BlurCardListData} />
      </div>
      <div className="mt-70"></div>
      <TwoColumnContactInfo
        TwoColumnContactInfoData={TwoColumnContactInfoData}
      />
    </Layout>
  );
};

export default MvpDevelopment;
